<template>
  <section class="bg-gray-50 py-12">
    <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <h2 class="text-3xl font-bold text-center mb-8">Câu hỏi thường gặp về dịch vụ thiết kế website</h2>
      <div class="space-y-4">
        <div v-for="(faq, index) in faqs" :key="index" class="bg-white p-6 rounded-lg shadow-md">
          <h3 class="text-xl font-semibold mb-2">{{ faq.question }}</h3>
          <p class="text-gray-600">{{ faq.answer }}</p>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
const faqs = [
  { 
    question: 'Bạn cung cấp những dịch vụ gì trong lĩnh vực thiết kế website và SEO?', 
    answer: 'Chúng tôi cung cấp các dịch vụ thiết kế website chuyên nghiệp, thiết kế website landing page giá rẻ, tối ưu hóa công cụ tìm kiếm (SEO) và dịch vụ quảng cáo trên Facebook, giúp bạn tăng tương tác và tiếp cận khách hàng.' 
  },
  { 
    question: 'Tôi muốn biết về giá thiết kế website và chi phí SEO?', 
    answer: 'Chúng tôi sẽ tiếp nhận yêu cầu chi tiết từ bạn, phân tích cụ thể và cung cấp báo giá chính xác cho từng loại dịch vụ, bao gồm thiết kế website, tối ưu SEO và chạy quảng cáo Facebook.' 
  },
  { 
    question: 'Thời gian hoàn thành 1 trang web chuẩn SEO là bao lâu?', 
    answer: 'Thời gian hoàn thành phụ thuộc vào độ phức tạp của trang web và yêu cầu SEO của bạn. Tuy nhiên, chúng tôi luôn nỗ lực để hoàn thành sớm nhất với chất lượng tốt nhất.' 
  },
  { 
    question: 'Bạn có cung cấp dịch vụ bảo trì và nâng cấp website không?', 
    answer: 'Có, chúng tôi cung cấp dịch vụ bảo trì và nâng cấp website trọn đời, đảm bảo trang web luôn hoạt động ổn định và được tối ưu hóa cho SEO và quảng cáo.' 
  },
  { 
    question: 'Làm thế nào để liên hệ với bạn để được tư vấn về thiết kế website và SEO?', 
    answer: 'Bạn có thể liên hệ với chúng tôi qua số hotline ở phần Footer, hoặc qua Email, Zalo, và Facebook để được tư vấn chi tiết về dịch vụ thiết kế website, tối ưu SEO và quảng cáo.' 
  },
];
</script>
