<template>
  <div class="container flex flex-col items-center mx-auto my-8 lg:mt-[100px]">
    <h1 class="text-3xl font-bold text-center mb-6 lg:mb-14">CÁC DỊCH VỤ THIẾT KẾ WEBSITE HIỆN TẠI</h1>

    <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
      <div class="bg-white p-6 rounded-lg shadow-md">
        <h1 class="text-xl font-semibold mb-2">1. Thiết kế website bán hàng cá nhân</h1>
        <p class="text-gray-600 mb-4">Thiết kế website bán hàng chuyên nghiệp cho riêng bạn, dễ dàng quản lý sản phẩm và tối ưu hóa đơn hàng…</p>
        <router-link to="/thiet-ke-website-ban-hang-ca-nhan">
          <button @click="saveScrollPosition" class="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600">Chi tiết</button>
        </router-link>
      </div>

      <div class="bg-white p-6 rounded-lg shadow-md">
        <h1 class="text-xl font-semibold mb-2">2. Thiết kế website Landing Page</h1>
        <p class="text-gray-600 mb-4">Xây dựng trang đích thu hút, thiết kế website chuẩn SEO để tối ưu hóa chuyển đổi khách hàng…</p>
        <router-link to="/thiet-ke-website-landing-page">
          <button @click="saveScrollPosition" class="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600">Chi tiết</button>
        </router-link>
      </div>

      <div class="bg-white p-6 rounded-lg shadow-md">
        <h1 class="text-xl font-semibold mb-2">3. Thiết kế website thương mại điện tử</h1>
        <p class="text-gray-600 mb-4">Phát triển nền tảng mua sắm trực tuyến mạnh mẽ và an toàn với thiết kế website thương mại điện tử chuyên nghiệp…</p>
        <router-link to="/thiet-ke-website-thuong-mai-dien-tu">
          <button @click="saveScrollPosition" class="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600">Chi tiết</button>
        </router-link>
      </div>

      <div class="bg-white p-6 rounded-lg shadow-md">
        <h1 class="text-xl font-semibold mb-2">4. Thiết kế website Blog gia đình, cá nhân, đám cưới, kỷ niệm</h1>
        <p class="text-gray-600 mb-4">Tạo một website cá nhân hóa cho gia đình, cá nhân của bạn, nơi lưu lại những khoảnh khắc đẹp và đáng nhớ…</p>
        <router-link to="/thiet-ke-website-blog">
          <button @click="saveScrollPosition" class="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600">Chi tiết</button>
        </router-link>
      </div>

      <div class="bg-white p-6 rounded-lg shadow-md">
        <h1 class="text-xl font-semibold mb-2">5. Hỗ trợ làm đồ án, thiết kế website giá rẻ cho sinh viên</h1>
        <p class="text-gray-600 mb-4">Cung cấp giải pháp và hướng dẫn chi tiết cho đồ án thiết kế website của sinh viên với chi phí thấp…</p>
        <router-link to="/thiet-ke-do-an-website">
          <button @click="saveScrollPosition" class="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600">Chi tiết</button>
        </router-link>
      </div>

      <div class="bg-white p-6 rounded-lg shadow-md">
        <h1 class="text-xl font-semibold mb-2">6. Chạy quảng cáo, dịch vụ tương tác Facebook, Tiktok, Youtube…</h1>
        <p class="text-gray-600 mb-4">Nâng cao thứ hạng tìm kiếm với dịch vụ tăng tương tác Facebook, Tiktok, giúp website của bạn tiếp cận nhiều khách hàng hơn…</p>
        <router-link to="/chay-quang-cao-website">
          <button @click="saveScrollPosition" class="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600">Chi tiết</button>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script setup>
  const saveScrollPosition = () => {
    sessionStorage.setItem('scrollPosition', window.scrollY); // Lưu vị trí cuộn hiện tại
  }
</script>

<style scoped>

</style>
