<template>
    <div class="flex flex-col items-center justify-center min-h-screen bg-gray-100">
      <div class="text-center">
        <h1 class="text-9xl font-bold text-gray-800">404</h1>
        <p class="text-2xl text-gray-600 mt-4">Oops! Page not found.</p>
        <p class="mt-2 text-gray-500">The page you're looking for doesn't exist or has been moved.</p>
        <router-link to="/" class="mt-6 inline-block px-8 py-3 bg-blue-600 text-white rounded hover:bg-blue-500 transition">
          Back to Home
        </router-link>
      </div>
    </div>
  </template>
  
  <script setup>
  // SEO meta tags setup using Vue's useHead composable from @vueuse/head
  import { useHead } from '@vueuse/head'
  
  useHead({
    title: '404 - Page Not Found | Your Website Design Services',
    meta: [
      {
        name: 'description',
        content: 'Page not found on Your Website Design Services. Return to the home page to explore our web design solutions.'
      },
      {
        property: 'og:title',
        content: '404 - Page Not Found | Your Website Design Services'
      },
      {
        property: 'og:description',
        content: 'Page not found. Return to our homepage to explore more.'
      },
      {
        property: 'og:type',
        content: 'website'
      }
    ]
  })
  </script>
  
  <style scoped>
  </style>
  