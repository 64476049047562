<template>
  <section class="bg-gray-100 py-12">
    <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
      <h2 class="text-3xl font-bold mb-8">Khách hàng của chúng tôi nói gì về dịch vụ thiết kế website</h2>
      <div class="grid gap-8 md:grid-cols-2 lg:grid-cols-3">
        <div v-for="(testimonial, index) in testimonials" :key="index" class="p-6 bg-white rounded-lg shadow-md">
          <p class="italic mb-4">"{{ testimonial.text }}"</p>
          <h3 class="text-xl font-semibold">{{ testimonial.author }}</h3>
          <p class="text-sm text-gray-500">{{ testimonial.position }}</p>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
const testimonials = [
  { text: 'Website giúp chúng tôi bán nhiều ghế hơn, tăng doanh thu cho cửa hàng.', author: 'Hoàng Minh', position: 'Cửa Hàng Ghế Massage' },
  { text: 'Khách hàng tìm xe dễ dàng và nhanh chóng hơn nhờ thiết kế website chuyên nghiệp.', author: 'Phương Linh', position: 'Chủ Cửa Hàng Xe Đạp' },
  { text: 'Tăng trưởng mạnh nhờ việc áp dụng quảng cáo trực tuyến và tối ưu SEO.', author: 'Anh Tuấn', position: 'Bán Hàng Online' },
  { text: 'Nhiều khách hàng hơn nhờ thiết kế website tối ưu hóa cho SEO.', author: 'Quốc Hùng', position: 'Cửa Hàng Điện Dân Dụng' },
  { text: 'Website thiết kế đẹp và dễ sử dụng, rất hài lòng.', author: 'Lan Anh', position: 'Cửa Hàng Thời Trang' },
  { text: 'Dịch vụ nhanh chóng và chuyên nghiệp, tôi rất tin tưởng.', author: 'Việt Cường', position: 'Cửa Hàng Máy Tính' }
];
</script>
