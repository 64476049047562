<template>
  <div class="relative">
    <img
      src="../assets/images/background/bg.webp"
      alt="Background - Thiết kế website chuẩn SEO"
      class="w-[430px] lg:w-full lg:h-[800px] lg:object-cover md:w-full md:h-[430px] md:object-contain"
    />
    <h1 class="custom-font absolute top-[20%] ml-[10px] text-[15px] text-white
            md:top-[20%] md:ml-[20px] md:text-[26px] md:p-[6px]
            lg:top-[15%] lg:ml-[50px] lg:text-[60px] lg:p-[10px]">
      "Chúng tôi không là duy nhất<br>nhưng chúng tôi luôn luôn làm tốt nhất."
    </h1>
  </div>
</template>

<script setup>
</script>

<style scoped>
.custom-font {
  font-family: Dancing script;
}
</style>
