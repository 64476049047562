<template>
<div>
    <section class="contact-section px-4 py-12 bg-gray-100">
    <div class="container mx-auto max-w-4xl">
      <h2 class="text-3xl font-semibold text-center mb-8">Liên hệ thiết kế website chuyên nghiệp</h2>
      <p class="text-center mb-8 text-lg lg:text-xl">
        Nếu bạn có bất kỳ câu hỏi nào hoặc muốn biết thêm về dịch vụ thiết kế website của chúng tôi, xin vui lòng liên hệ qua biểu mẫu dưới đây.
      </p>
      <form @submit.prevent="submitForm" class="space-y-6">
        <div class="space-y-6">
          <div>
            <label for="name" class="block text-md font-medium text-gray-700">Tên của bạn</label>
            <input
              type="text"
              id="name"
              v-model="formData.name"
              required
              minlength="2"
              class="mt-1 block w-full h-12 border-gray-300 rounded-md px-2 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
            />
          </div>
          <div>
            <label for="email" class="block text-md font-medium text-gray-700">Email</label>
            <input
              type="email"
              id="email"
              v-model="formData.email"
              required
              class="mt-1 block w-full h-12 border-gray-300 rounded-md px-2 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
            />
          </div>
          <div>
            <label for="confirmEmail" class="block text-md font-medium text-gray-700">Xác nhận Email</label>
            <input
              type="email"
              id="confirmEmail"
              v-model="formData.confirmEmail"
              required
              @input="checkEmailMatch"
              class="mt-1 block w-full h-12 border-gray-300 rounded-md px-2 shadow-sm focus:border-red-500 focus:ring-red-500 sm:text-sm"
            />
            <p v-if="emailMismatch" class="text-red-600 text-sm mt-1">Email không khớp.</p>
          </div>
          <div>
            <label for="service" class="block text-md font-medium text-gray-700">Dịch vụ quan tâm</label>
            <select
              id="service"
              v-model="formData.service"
              required
              class="mt-1 block w-full h-12 border-gray-300 rounded-md px-2 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
            >
              <option disabled value="">Chọn một dịch vụ</option>
              <option value="web_design">Thiết kế Web chuyên nghiệp</option>
              <option value="consulting">Tư vấn SEO</option>
              <option value="web-help">Hỗ trợ đồ án website</option>
              <option value="ads">Chạy quảng cáo trực tuyến</option>
              <!-- Thêm các dịch vụ khác nếu cần -->
            </select>
          </div>
          <div>
            <label for="message" class="block text-md font-medium text-gray-700">Lời nhắn</label>
            <textarea
              id="message"
              v-model="formData.message"
              rows="4"
              required
              minlength="10"
              class="mt-1 block w-full border-gray-300 rounded-md px-2 py-2 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
            ></textarea>
          </div>
        </div>
        <div class="text-center">
          <button
            type="submit"
            :disabled="loading"
            class="inline-flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            <span v-if="loading" class="loader mr-2"></span> Gửi Lời Nhắn
          </button>
        </div>
        <p v-if="submitted" class="text-center mt-4 text-green-600">Lời nhắn của bạn đã được gửi thành công!</p>
        <p v-if="error" class="text-center mt-4 text-red-600">Đã có lỗi xảy ra khi gửi lời nhắn, vui lòng thử lại.</p>
      </form>

      <div class="mt-8 text-center">
        <h3 class="text-xl font-semibold">Hoặc liên hệ qua mạng xã hội</h3>
        <div class="flex justify-center space-x-4 mt-4">
          <a href="https://www.facebook.com/profile.php?id=61560331987437" target="_blank" class="text-blue-600 hover:underline">Facebook</a>
          <a href="https://zalo.me/0905514868" target="_blank" class="text-blue-600 hover:underline">Zalo</a>
          <span class="text-blue-600">Hotline: 0905 514 868</span>
        </div>
      </div>
    </div>
  </section>

  <Footer/>
</div>
</template>

<script setup>
import { reactive, ref } from 'vue';
import Footer from "../components/Footer.vue";

const formData = reactive({
  name: '',
  email: '',
  confirmEmail: '',
  service: '',
  message: '',
});

const emailMismatch = ref(false);
const submitted = ref(false);
const error = ref(false);
const loading = ref(false);

const checkEmailMatch = () => {
  emailMismatch.value = formData.email !== formData.confirmEmail;
};

const submitForm = async () => {
  if (emailMismatch.value) return;

  loading.value = true;
  error.value = false;
  submitted.value = false;

  try {
    const response = await fetch('https://formspree.io/f/xrbzakyv', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        name: formData.name,
        email: formData.email,
        service: formData.service,
        message: formData.message,
      }),
    });

    if (response.ok) {
      submitted.value = true;
      formData.name = '';
      formData.email = '';
      formData.confirmEmail = '';
      formData.service = '';
      formData.message = '';
    } else {
      error.value = true;
    }
  } catch {
    error.value = true;
  } finally {
    loading.value = false;
  }
};
</script>

<style scoped>
.loader {
  border: 4px solid #f3f3f3;
  border-radius: 50%;
  border-top: 4px solid #3498db;
  width: 16px;
  height: 16px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
</style>
