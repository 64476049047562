<template>
  <section class="bg-blue-600 text-white py-12">
    <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
      <h2 class="text-3xl font-bold mb-4">Sẵn sàng bắt đầu với dịch vụ thiết kế website?</h2>
      <p class="mb-8">Liên hệ với chúng tôi ngay hôm nay để thảo luận về dự án thiết kế website của bạn.</p>
      <a href="/contact" class="bg-white text-blue-600 font-semibold py-3 px-6 rounded-lg shadow-md hover:bg-gray-200">
        Liên hệ với chúng tôi
      </a>
    </div>
  </section>
</template>
