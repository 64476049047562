<template>
  <div v-show="showButton" @click="scrollToTop" class="fixed bottom-4 right-4 p-2 rounded-full cursor-pointer">
    <img src="../assets/images/arow/arow-up.webp" alt="Scroll to top" class="w-10 h-10">
  </div>
</template>

<script setup>
import { ref, onMounted, onUnmounted } from 'vue';

const showButton = ref(false);

const checkScroll = () => {
  showButton.value = window.scrollY > 200;
};

const scrollToTop = () => {
  window.scrollTo({ top: 0, behavior: 'smooth' });
};

onMounted(() => {
  window.addEventListener('scroll', checkScroll);
});

onUnmounted(() => {
  window.removeEventListener('scroll', checkScroll);
});
</script>

<style scoped>
/* Thêm style tùy chỉnh nếu cần */
</style>
