<template>
  <header class="bg-white shadow p-4">
    <div class="container mx-auto flex justify-between items-center">
      <!-- Logo -->
      <div :class="{ hidden: isMenuOpen, block: !isMenuOpen }" class="lg:w-[420px] md:w-[240px] flex flex-row items-center justify-around">
        <router-link to="/">
          <img src="../assets/images/logo/logo.webp" alt="Logo - Thiết kế website chuyên nghiệp chuẩn SEO giá rẻ" class="h-12 md:h-24 lg:h-30"/>
        </router-link>

        <router-link to="/">
          <h1 class="custom-font text-yellow-600 font-bold ml-[10px] text-[20px] lg:text-[45px] md:text-[26px] md:ml-0">
            Dịch vụ website
          </h1>
        </router-link>
      </div>

      <!--------------- NAVIGATION--------------->
      <nav class="navigation ml-auto">
        <!-- Nút hamburger chỉ hiển thị trên di động -->
        <button @click="toggleMenu" class="block md:hidden ml-auto">
          <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16"></path>
          </svg>
        </button>

        <!-- Menu điều hướng -->
        <ul :class="{ hidden: !isMenuOpen,block: isMenuOpen,
            'md:flex': true, // Hiển thị flex trên desktop
            'md:flex-row': true, // Hiển thị theo hàng ngang trên desktop
            'md:space-x-4': true, // Thêm khoảng cách giữa các item trên desktop
            'w-full': true, // Chiếm toàn bộ chiều rộng
          }"
        >
          <li @click="toggleMenu">
            <router-link to="/" class="text-blue-600 font-bold lg:text-[20px] hover:text-blue-500 block lg:px-3 md:px-2">
              TRANG CHỦ
            </router-link>
          </li>

          <li @click="toggleMenu">
            <router-link to="/about" class="text-blue-600 font-bold lg:text-[20px] hover:text-blue-500 block lg:px-3 md:px-2">
              GIỚI THIỆU
            </router-link>
          </li>

          <li @click="toggleMenu">
            <router-link to="/web-guide" class="text-blue-600 font-bold lg:text-[20px] hover:text-blue-500 block lg:px-3 md:px-2">
              CẨM NANG WEBSITE
            </router-link>
          </li>

          <li @click="toggleMenu">
            <router-link to="/contact" class="text-blue-600 font-bold lg:text-[20px] hover:text-blue-500 block lg:px-3 md:px-2">
              LIÊN HỆ
            </router-link>
          </li>
        </ul>
      </nav>
    </div>
  </header>
</template>

<script setup>
import { ref } from "vue";

const isMenuOpen = ref(false);

const toggleMenu = () => {
  if (window.innerWidth < 768) {
    isMenuOpen.value = !isMenuOpen.value;
  }
};
</script>

<style scoped>
.custom-font {
  font-family: "Great Vibes", cursive;
  letter-spacing: 2px;
}
</style>
